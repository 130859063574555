import { useEffect } from 'react'

import { useSetRecoilState } from 'recoil'
import RoutesApprovalsByFleet from './RoutesApprovalsByFleet'

import navState from '../atoms/NavState'

const ApprovalRoutes = () => {
  const setNavStateStatus = useSetRecoilState(navState)
  useEffect(() => {
    setNavStateStatus({ menu: 2, submenu: '' })
  }, [])
  console.log('routes')

  if (process.env.REACT_APP_STATUS === 'LOCAL' || process.env.REACT_APP_STATUS === 'DEV') {
    return (
      <RoutesApprovalsByFleet />
    )
  }
  return (
    <p>not available</p>
  )
}

export default ApprovalRoutes
